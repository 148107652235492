import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupAddSharpIcon from '@material-ui/icons/GroupAddSharp';
import Apartment from '@material-ui/icons/Apartment';

//Set the tooltip for buttons
export const GetToolTip = (action) => {
  switch (action) {
    case "AllDistrict":
      return "District Sync";
    case "District::1":
    case "School::1":
    case "SyncNweaDynamoSchools::1":
    case "SyncNweaDynamoStudents::1":
      return "Sync Students";
    case "District::2":
    case "SyncNweaDynamoSchools::2":
      return "Sync Schools";
    case "School::2":
      return "Sync Student Assessments";
    case "RenSchool::1":
      return "Sync Student Assessments";
    case "DisabledRenSchool::1":
      return "Sync Not Enabled for DynamoDB";
    default:
      return "";
  }
}

//Set the number of buttons
const GetButtonsPerType = (syncKey) => {
  let num = 0;
  switch (syncKey) {
    case "SyncNweaDynamoSchools":
      num = 2;
      break;
    case "SyncNweaDynamoStudents":
      num = 1;
      break;
    case "District":
    case "School":
      num = 2;
      break;
    case "RenSchool":
    case "DisabledRenSchool":
      num = 1;
      break;
    default:
      num = 0;
  }
  return num;
};

//Set the button Icons
const GetIcons = (action) => {
  switch (action) {
    case "SyncNweaDynamoSchools::1":
      return <GroupAddSharpIcon />;  
    case "SyncNweaDynamoSchools::2":
      return <Apartment />;
    case "District::1":
    case "School::1"  :
    case "SyncNweaDynamoStudents::1":
      return <GroupAddSharpIcon />;
    case "District::2":
      return <Apartment />;
    case "School::2":
    case "RenSchool::1":
      return (<AssessmentIcon />);
    case "DisabledRenSchool::1":
      return (<AssessmentIcon color='disabled'/>);
    default:
      return <></>;
  }
}

const IsButtonDisabled = (key)=>{
  return (key === "DisabledRenSchool::1");
};

export const getRowActions = (props) => {
  const { syncKey } = props;
  let num = GetButtonsPerType(syncKey);
  let list = [];
  for (let i = 1; i <= num; i++) {
    let key = syncKey + "::" + i
    list.push({
      icon: () => GetIcons(key),
      tooltip: GetToolTip(key),
      disabled:IsButtonDisabled(key),
      onClick: (event, rowData) => props.handleDialogOpen(event, rowData, key),
    })
  };
  return list;
};